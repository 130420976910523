import {NavLink} from "react-router-dom";
import {Fragment} from "react";
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html";

function CustomerTaskRow({customerTask}) {
	let comments = null;
	try {
		comments = new QuillDeltaToHtmlConverter(JSON.parse(customerTask.comments)).convert();
	}
	catch (e) {

	}
	return (
		<tr>
			<td>{customerTask.customer}</td>
			<td>{customerTask.code}</td>
			<td><NavLink to={'/customerTasks/' + customerTask.id}>{customerTask.name}</NavLink></td>
			<td>{customerTask.description}</td>
			<td dangerouslySetInnerHTML={{
				__html: comments
			}} />
			<td>{customerTask.externalCosts ? <Fragment>&euro;{customerTask.externalCosts.toLocaleString()}</Fragment>: 'N.A.'}</td>
			<td>{customerTask.internalCosts ? <Fragment>&euro;{customerTask.internalCosts.toLocaleString()}</Fragment>: 'N.A.'}</td>
			<td>{customerTask.estimatedBonus ? <Fragment>&euro;{customerTask.estimatedBonus.toLocaleString()}</Fragment>: 'N.A.'}</td>
			<td>{customerTask.estimatedInvoiceValue ? <Fragment>&euro;{customerTask.estimatedInvoiceValue.toLocaleString()}</Fragment>: "N.A."}</td>
			<td>{customerTask.expectedDueDate}</td>
			<td>{customerTask.poNumber}</td>
			<td>{customerTask.invoiceAmount ? <Fragment>&euro;{customerTask.invoiceAmount.toLocaleString()}</Fragment>: 'N.A.'}</td>
			<td>{customerTask.finalAmount ? <Fragment>&euro;{customerTask.finalAmount.toLocaleString()}</Fragment>: "N.A."}</td>
			<td>{customerTask.invoiceDate}</td>
			<td>{customerTask.invoiceNumber}</td>
			<td>{customerTask.invoiced ? <input type={"checkbox"} checked={true} disabled={true} />: <input disabled={true} type={"checkbox"} checked={false} />}</td>
		</tr>
	);
}

export default CustomerTaskRow;